import {
  sendShopifyAnalytics,
  getClientBrowserParameters,
} from "@shopify/hydrogen-react"

const ANALYTICS_SHOP_DATA = {
  shopId: "gid://shopify/Shop/28543844405",
  currency: "EUR",
  acceptedLanguage: "de",
}

export const trackEvent = (name, params) => {
  if (typeof window !== `undefined`) {
    window.dataLayer = window?.dataLayer || []

    if ("ecommerce" in params) {
      window.dataLayer.push({ ecommerce: null })
    }

    window.dataLayer.push({
      event: name,
      ...params,
    })
  }
}

export const trackShopify = (event, data) => {
  try {
    const payload = {
      ...getClientBrowserParameters(),
      hasUserConsent: true,
      ...ANALYTICS_SHOP_DATA,
      ...data,
    }

    sendShopifyAnalytics({
      eventName: event,
      payload,
    })
  } catch (e) {
    console.error(e)
  }
}
