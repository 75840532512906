export const defaultCartFragment = /* GraphQL */ `
  fragment CartFragment on Cart {
    id
    checkoutUrl
    totalQuantity
    buyerIdentity {
      countryCode
      customer {
        id
        email
        firstName
        lastName
        displayName
      }
      email
      phone
    }
    lines(first: $numCartLines) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
          }
          discountAllocations {
            ... on CartCodeDiscountAllocation {
              code
              discountedAmount {
                ...MoneyFragment
              }
            }
          }
          sellingPlanAllocation {
            sellingPlan {
              id
              name
              recurringDeliveries
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              sku
              weight
              weightUnit
              availableForSale
              compareAtPrice {
                ...MoneyFragment
              }
              price {
                ...MoneyFragment
              }
              requiresShipping
              title
              image {
                ...ImageFragment
              }
              product {
                handle
                title
                id
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    cost {
      checkoutChargeAmount {
        ...MoneyFragment
      }
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
    }
    note
    attributes {
      key
      value
    }
    discountAllocations {
      ... on CartCodeDiscountAllocation {
        code
        discountedAmount {
          ...MoneyFragment
        }
      }
    }
    discountCodes {
      code
    }
  }
  fragment MoneyFragment on MoneyV2 {
    currencyCode
    amount
  }
  fragment ImageFragment on Image {
    id
    url
    altText
    width
    height
  }
`
