import fetch from "isomorphic-fetch"
import React, { createContext, useEffect, useState } from "react"
import ClientUnoptimized from "shopify-buy/index.unoptimized.umd"

import { trackEvent, trackShopify } from "../business/track-event"
import UTM from "../business/utm"

import { AnalyticsEventName, useCart } from "@shopify/hydrogen-react"

import { graphql, useStaticQuery } from "gatsby"

const MIN_AVAILABLE = 30

const SHIPPING = 3.9
const SHIPPING_INTL = 9.9

const SHIPPING_THRESHOLD = 60
const SHIPPING_THRESHOLD_INTL = 75

const INTERNATIONAL_COUNTRIES = [
  "AD",
  "BE",
  "BG",
  "CH",
  "CY",
  "CZ",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LI",
  "LT",
  "LU",
  "LV",
  "MC",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
  "SM",
  "VA",
]

const clientUnoptimized = ClientUnoptimized.buildClient(
  {
    domain: "checkout.brandl-nutrition.de",
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch,
)

const defaultValues = {
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  addVariantsToCart: () => {},
  removeLineItem: () => {},
  isVariantAvailable: () => {},
}

export const StoreContext = createContext(defaultValues)

const LOADING_STATUS = ["fetching", "creating", "updating"]

export const StoreProvider = ({ children }) => {
  const { config, products, contentful } = useStaticQuery(graphql`
    query {
      config: contentfulConfiguration {
        freeUpsellAmount
        freeUpsell {
          coupon
        }
      }
      products: allShopifyProduct {
        nodes {
          variants {
            shopifyId
            product {
              shopifyId
              handle
              title
            }
            weightUnit
            weight
            price
            sku
          }
        }
      }
    }
  `)

  const {
    status,
    cost,
    cartCreate,
    linesAdd,
    linesRemove,
    linesUpdate,
    cartAttributesUpdate,
    discountCodesUpdate,
    discountAllocations,
    discountCodes,
    checkoutUrl,
    id: cartId,
    lines: cartLines,
    buyerIdentity,
    ...rest
  } = useCart()

  const allVariants = products.nodes.flatMap(p => p.variants)
  const lines = cartLines || []

  const [cartIsOpen, setCartIsOpen] = useState(false)

  const cartDiscount = discountAllocations?.reduce(
    (a, c) => a + parseFloat(c.discountedAmount?.amount || "0"),
    0,
  )

  const linesDiscount = lines.reduce(
    (a, c) =>
      a +
      c.discountAllocations?.reduce(
        (ax, cx) => ax + parseFloat(cx.discountedAmount?.amount || "0"),
        0,
      ),
    0,
  )

  const discountAmount = cartDiscount + linesDiscount

  const total = parseFloat(cost?.totalAmount.amount || 0)
  const totalWithoutDiscount = total + discountAmount

  let shippingAmount = 0
  let totalWithShipping = total

  if (
    buyerIdentity?.countryCode === "DE" ||
    buyerIdentity?.countryCode === "AT"
  ) {
    shippingAmount = total > SHIPPING_THRESHOLD || total === 0 ? 0 : SHIPPING
  } else if (INTERNATIONAL_COUNTRIES.includes(buyerIdentity?.countryCode)) {
    shippingAmount =
      total > SHIPPING_THRESHOLD_INTL || total === 0 ? 0 : SHIPPING_INTL
  } else {
    shippingAmount = total > SHIPPING_THRESHOLD || total === 0 ? 0 : SHIPPING
  }

  totalWithShipping = total + shippingAmount

  const freeUpsellMissing = config?.freeUpsellAmount - total

  const allCodes = discountCodes?.map(c => c.code.toLowerCase())
  const freeUpsellInCart = config?.freeUpsell?.some(u =>
    allCodes?.includes(u.coupon.toLowerCase()),
  )

  const choosingFreeUpsell = freeUpsellMissing <= 0 && !freeUpsellInCart

  const loading = LOADING_STATUS.includes(status)

  const gqlClient = clientUnoptimized.graphQLClient

  const lineIds = lines.map(l => l.id).join(",")

  useEffect(() => {
    if (status === "uninitialized" && checkoutUrl === undefined) {
      cartCreate({})
    }
  })

  useEffect(() => {
    if (status === "uninitialized") {
      return
    }

    const bundles = lines
      .filter(line => line.attributes.find(a => a.key === "_bundle"))
      .map(l =>
        Object.fromEntries(
          l.attributes.map(a => [a.key.substring(1), a.value]),
        ),
      )

    const freeUpsellCoupons = lines
      .map(line =>
        line.attributes.find(a => a.key === "_coupon")?.value.toLowerCase(),
      )
      .filter(l => l)

    const autoCoupons = lines
      .map(line =>
        line.attributes.find(a => a.key === "_autoCoupon")?.value.toLowerCase(),
      )
      .filter(l => l)

    if (freeUpsellMissing > 0 && freeUpsellCoupons.length > 0) {
      const idsToRemove = lines
        .filter(
          l =>
            l.attributes?.find(a => a.key === "_type")?.value === "freeUpsell",
        )
        .map(l => l.id)

      if (idsToRemove.length > 0) {
        removeLineItems(idsToRemove)
        return
      }
    }

    const allFreeUpsellCoupons = config?.freeUpsell
      ?.map(f => f.coupon.toLowerCase())
      .filter(c => !freeUpsellCoupons.includes(c))

    const allAutoCoupons =
      contentful?.nodes
        .filter(c => c.autoCoupon)
        .map(c => c.autoCoupon.toLowerCase())
        .filter(c => !autoCoupons.includes(c)) || []

    const bundleIds = new Set(bundles.map(b => b.bundle))
    const couponsToAdd = new Set([
      "BUNDLE33",
      ...freeUpsellCoupons,
      ...autoCoupons,
    ])
    const couponsToRemove = new Set([
      ...allFreeUpsellCoupons,
      ...allAutoCoupons,
      "birthday10",
      "birthday15",
      "health20",
      "halloween15",
      "blackfriday15",
      "blackfriday25",
      "blackfriday40",
      "januar10",
      "januar15",
      "ostern",
    ])

    bundleIds.forEach(bundleId => {
      const bundleConfig = bundles.find(b => b.bundle === bundleId)
      const realCount = bundles.filter(b => b.bundle === bundleId).length

      if (realCount === parseInt(bundleConfig.bundleCount)) {
        couponsToAdd.add(bundleConfig.bundleCoupon.toLowerCase())
      } else {
        couponsToRemove.add(bundleConfig.bundleCoupon.toLowerCase())
      }
    })

    if (couponsToAdd.size > 0 || couponsToRemove.size > 0) {
      const existingCodes = discountCodes?.map(c => c.code.toLowerCase())
      const toAdd = [...couponsToAdd].filter(c => !existingCodes?.includes(c))
      const toRemove = [...couponsToRemove].filter(
        c => !toAdd.includes(c) && existingCodes?.includes(c),
      )

      if (toAdd.length > 0 || toRemove.length > 0) {
        discountCodesUpdate([
          ...existingCodes?.filter(c => !toRemove.includes(c)),
          ...toAdd,
        ])
      }
    }
  }, [lineIds, total])

  useEffect(() => {
    if (typeof window !== "undefined" && status !== "uninitialized") {
      const urlSearchParams = new URLSearchParams(
        window?.location?.search ?? "",
      )

      const params = Object.fromEntries(urlSearchParams.entries())

      if (
        params?.coupon?.length > 0 &&
        !discountCodes.some(
          dc => dc.code.toLowerCase() === params.coupon.toLowerCase(),
        )
      ) {
        const search = Object.keys(params)
          .filter(k => k !== "coupon")
          .map(k => `${k}=${params[k]}`)
          .join("&")

        window?.history?.replaceState(
          null,
          null,
          window?.location?.pathname +
            (search.length > 1 ? `?${search}` : "") +
            window?.location?.hash,
        )

        applyCoupon(params.coupon)
      }
    }
  }, [checkoutUrl])

  const isVariantAvailable = (
    productId,
    variantId,
    ignoreThreshold = false,
  ) => {
    const id = gqlClient.variable("id", "ID!")

    const productQuery = gqlClient.query("getProductById", [id], root => {
      root.add("product", { args: { id } }, p => {
        p.addConnection("variants", { args: { first: 100 } }, v => {
          v.add("id")
          v.add("availableForSale")
          v.add("quantityAvailable")
        })
      })
    })

    let decodedVariantId = variantId
    if (typeof window !== "undefined") {
      try {
        decodedVariantId = atob(variantId)
      } catch (e) {}
    }

    return gqlClient
      .send(productQuery, { id: productId })
      .then(({ data }) => {
        const variant = data?.product?.variants?.edges?.find(
          e => e.node.id === variantId || e.node.id === decodedVariantId,
        )?.node

        return (
          variant &&
          variant.availableForSale &&
          (ignoreThreshold || variant.quantityAvailable >= MIN_AVAILABLE)
        )
      })
      .catch(err => {
        return false
      })
  }

  const getLineItemData = lineItem => ({
    item_name: lineItem.merchandise?.product?.title,
    item_id: lineItem.merchandise?.product?.handle,
    price: parseFloat(lineItem.merchandise?.price?.amount),
    currency: lineItem.merchandise?.price?.currencyCode,
    item_variant: lineItem.merchandise?.sku,
    quantity: lineItem.quantity,
  })

  const getAllCartItems = () => {
    const coupon = discountCodes?.map(d => d.code).join(",")
    const data = lines.map((item, idx) => ({
      item_id: item.merchandise.product.handle,
      item_name: item.merchandise.product.title,
      coupon: coupon,
      currency: item.merchandise?.price?.currencyCode,
      discount: item.discountAllocations?.reduce(
        (a, d) => parseFloat(d.discountedAmount?.amount) + a,
        0,
      ),
      index: idx,
      item_variant: item.merchandise.sku,
      price: parseFloat(item.merchandise.price.amount),
      quantity: item.quantity,
    }))

    return data
  }

  const addVariantsToCart = variants => {
    const lineItems = variants.map(variant => {
      const lineItem = {
        merchandiseId: variant.variantId,
        quantity: 1,
      }

      if (variant.sellingPlanId) {
        lineItem.sellingPlanId = variant.sellingPlanId
      }

      if (variant.attributes) {
        const customAttributes = Object.entries(variant.attributes).filter(
          ([_, value]) => value,
        )

        if (customAttributes.length >= 1) {
          lineItem.attributes = customAttributes.map(([key, value]) => ({
            key: `_${key}`,
            value: value,
          }))
        }
      }

      return lineItem
    })

    linesAdd(lineItems)
    setCartIsOpen(true)

    if (
      typeof window !== `undefined` &&
      process.env.NODE_ENV === `production`
    ) {
      const variantIds = variants.map(variant => variant.variantId)
      const addedItems = variantIds.map(id =>
        allVariants.find(v => v.shopifyId === id),
      )

      if (addedItems?.length > 0) {
        trackEvent("add_to_cart", {
          ecommerce: {
            items: addedItems.map(i => ({
              item_name: i.product?.title,
              item_id: i.product?.handle,
              price: parseFloat(i.price),
              currency: "EUR",
              item_variant: i.sku,
              quantity: 1,
            })),
            checkoutUrl: checkoutUrl,
          },
        })

        if (typeof window.fbq === "function") {
          window.fbq("track", "AddToCart", {
            content_type: "product",
            content_ids: addedItems.map(i => {
              const parts = i.product.shopifyId.split("/")
              return parts[parts.length - 1]
            }),
          })
        }

        const eventData = {
          cartId: cartId,
          products: addedItems.map(item => ({
            productGid: item.product?.shopifyId,
            name: item.product?.title,
            brand: "Brandl Nutrition",
            cost: item.price,
            variantGid: item.shopifyId,
            sku: item.sku,
            quantity: 1,
          })),
        }

        trackShopify(AnalyticsEventName.ADD_TO_CART, eventData)
      }
    }
  }

  const addVariantToCart = (variantId, attributes = undefined) => {
    addVariantsToCart([{ variantId, attributes }])
  }

  const addSubscriptionToCart = (
    merchandiseId,
    sellingPlanId,
    attributes = undefined,
  ) => {
    const sellingPlan = btoa(`gid://shopify/SellingPlan/${sellingPlanId}`)
    addVariantsToCart([
      {
        variantId: merchandiseId,
        sellingPlanId: sellingPlan,
        attributes,
      },
    ])
  }

  const changeQuantity = (lineItemID, quantity) => {
    const lineItem = lines.find(i => i.id === lineItemID)
    const newQuantity = lineItem.quantity + quantity

    if (newQuantity > 0) {
      linesUpdate([
        {
          id: lineItemID,
          quantity: newQuantity,
          attributes: lineItem.attributes,
        },
      ])
    } else {
      removeLineItem(lineItemID)
    }
  }

  const removeLineItems = lineItemIDs => {
    const removedItems = lines.filter(i => lineItemIDs.includes(i.id))

    linesRemove(lineItemIDs)

    if (removedItems?.length > 0) {
      trackEvent("remove_from_cart", {
        ecommerce: {
          items: removedItems.map(i => getLineItemData(i)),
        },
      })
    }
  }

  const removeLineItem = lineItemID => {
    return removeLineItems([lineItemID])
  }

  const clearCart = () => {
    removeLineItems(lines.map(i => i.id))
  }

  const applyCoupon = code => {
    const codes = discountCodes.map(c => c.code.toLowerCase())
    const newCode = code.toLowerCase()

    if (!codes.includes(newCode)) {
      discountCodesUpdate([...codes, newCode])
    }
  }

  const removeCoupon = code => {
    const newCodes = discountCodes
      .map(c => c.code.toLowerCase())
      .filter(c => c !== code.toLowerCase())

    if (newCodes.length < discountCodes.length) {
      discountCodesUpdate(newCodes)
    }
  }

  const updateCartAttributes = attributes => {
    const updates = Object.entries(attributes || {})
      .filter(([_, v]) => v)
      .map(([k, v]) => ({ key: k, value: String(v) }))

    if (updates.length > 0) {
      cartAttributesUpdate(updates)
    }
  }

  const startCheckout = () => {
    const attributes = UTM.get()
    const times = UTM.getTimes()
    const ghref = attributes["ghref"] || attributes["initial_ghref"]

    if (ghref && new Date() - new Date(times["ghref"]) < 1000 * 60 * 60 * 24) {
      attributes["gh_partner_id"] = ghref
    }

    attributes["ga_active"] =
      typeof window !== "undefined" && window?.ga && window?.ga?.loaded
        ? "true"
        : "false"

    updateCartAttributes(attributes)

    trackEvent("begin_checkout", {
      ecommerce: {
        items: getAllCartItems(),
      },
    })

    const discountsString = [
      ...new Set(discountCodes.map(c => c.code.toUpperCase())),
    ].join("%2C")
    const urlWithDiscounts = checkoutUrl + "?discount=" + discountsString

    global.location.href = urlWithDiscounts
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addVariantsToCart,
        addSubscriptionToCart,
        removeLineItem,
        changeQuantity,
        clearCart,
        applyCoupon,
        removeCoupon,
        startCheckout,
        isVariantAvailable,
        discountCodes,
        discountAllocations,
        cost,
        lines,
        cartIsOpen,
        setCartIsOpen,
        loading,
        getAllCartItems,
        choosingFreeUpsell,
        freeUpsellMissing,
        discountAmount,
        shippingAmount,
        total,
        totalWithoutDiscount,
        totalWithShipping,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
