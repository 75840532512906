import React, { useEffect, useState, createContext } from "react"
import { GLOBAL_MODALS } from "../config/global"
import { graphql, useStaticQuery } from "gatsby"
import store from "store2"

const defaultValues = {
  isGModalOpen: false,
  content: null,
  loading: false,
  openModal: (modalInfo = null) => {},
  closeModal: () => {},
}

export const ModalContext = createContext(defaultValues)

export const ModalProvider = ({ children }) => {
  const dataKey = `modal_data`
  const storage = store.namespace(dataKey)

  const [isGModalOpen, setIsGModalOpen] = useState(false)
  const [content, setContent] = useState(null)

  const { contentfulConfig } = useStaticQuery(graphql`
    query {
      contentfulConfig: contentfulConfiguration {
        shippingInfo {
          raw
        }
      }
    }
  `)

  const openModal = (modalInfo = null) => {
    if (modalInfo === GLOBAL_MODALS.SHIPPING_INFO) {
      setContent(contentfulConfig.shippingInfo)
    }
    setIsGModalOpen(true)
  }

  const closeModal = () => {
    setIsGModalOpen(false)
  }

  return (
    <ModalContext.Provider
      value={{
        ...defaultValues,
        isGModalOpen,
        content,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
