import React, { createContext, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import store from "store2"

const defaultValues = {
  answers: {},
  updateAnswers: () => {},
  lastViewed: [],
  setProductVisited: () => {},
}

export const QaContext = createContext(defaultValues)

const dataKey = `qa_data`
const storage = store.namespace(dataKey)

const lastViewedKey = "lastViewed"
const lastViewedStorage = store.namespace(lastViewedKey)

const loadDefaultParams = products => {
  const questionMap = Object.fromEntries(
    products
      .map(
        product =>
          product.questions?.map(({ key, ...data }) => [key, data]) || [],
      )
      .flat(),
  )

  const storedAnswers = storage.get(dataKey)

  let existingAnswers = null

  if (storedAnswers && storedAnswers !== `null`) {
    try {
      existingAnswers = JSON.parse(storedAnswers)
    } catch {}
  }

  const defaultParams = [...new Set(Object.keys(questionMap))].map(key => [
    key,
    existingAnswers?.[key],
  ])

  defaultParams.push(
    ...[
      ...new Set(
        products
          .map(
            product =>
              product.optionMappings?.map(({ question }) => question) || [],
          )
          .flat()
          .filter(question => !(question in questionMap)),
      ),
    ].map(q => [q, existingAnswers?.[q]]),
  )

  return Object.fromEntries(defaultParams)
}

const getLastViewed = () => {
  let existing = []

  if (typeof document === "undefined") return existing

  const storedLastViewed = lastViewedStorage.get(lastViewedKey)

  if (storedLastViewed && storedLastViewed !== `null`) {
    try {
      existing = JSON.parse(storedLastViewed)
    } catch {}
  }

  return existing
}

export const QaProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      products: allProductsJson {
        nodes {
          questions {
            key
            type
          }
          optionMappings {
            question
          }
        }
      }
    }
  `)

  const defaultParams = loadDefaultParams(data.products.nodes)

  const [answers, setAnswers] = useState(defaultParams)
  const lastViewed = getLastViewed()

  const updateAnswers = newAnswers => {
    setAnswers(existingAnswers => {
      if (newAnswers["vegan"] === "no") {
        newAnswers["lactose_intolerance"] = undefined
      }
      const updatedAnswers = { ...existingAnswers, ...newAnswers }
      storage.set(dataKey, JSON.stringify(updatedAnswers))
      return updatedAnswers
    })
  }

  const setProductVisited = handle => {
    const updatedLastViewed = [
      handle,
      ...getLastViewed()
        .filter(h => h !== handle)
        .splice(0, 5),
    ]

    lastViewedStorage.set(lastViewedKey, JSON.stringify(updatedLastViewed))
  }

  return (
    <QaContext.Provider
      value={{
        ...defaultValues,
        answers,
        updateAnswers,
        lastViewed,
        setProductVisited,
      }}
    >
      {children}
    </QaContext.Provider>
  )
}
