import React, { useEffect, useState } from "react"
import { defaultCartFragment } from "../../graphql/cart-fragment"
import { CartProvider, ShopifyProvider } from "@shopify/hydrogen-react"

let isHydrating = true

const ClientCartProvider = ({ children }) => {
  let [isHydrated, setIsHydrated] = useState(!isHydrating)

  useEffect(() => {
    isHydrating = false
    setIsHydrated(true)
  }, [])

  if (isHydrated) {
    return (
      <ShopifyProvider
        storeDomain="https://checkout.brandl-nutrition.de"
        storefrontToken={process.env.GATSBY_STOREFRONT_ACCESS_TOKEN}
        storefrontApiVersion="2024-04"
        countryIsoCode="DE"
        languageIsoCode="DE"
      >
        <CartProvider cartFragment={defaultCartFragment}>
          {children}
        </CartProvider>
      </ShopifyProvider>
    )
  } else {
    return null
  }
}

export default ClientCartProvider
