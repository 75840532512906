import store from "store2"
import "url-polyfill"

const ALLOWED_PARAMS = [
  "adcref",
  "ghref",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_name",
  "utm_term",
  "referrer",
  "initial_ghref",
  "initial_utm_source",
  "initial_utm_medium",
  "initial_utm_campaign",
  "initial_utm_content",
  "initial_utm_name",
  "initial_utm_term",
  "initial_referrer",
  "gclid",
]

const storage = store.namespace("utmSavedParams")
const updateTimes = store.namespace("utmSavedParamsTime")
const session = store.namespace("utmSession")

class UTMParams {
  static parse() {
    const urlSearch = new URL(window.location)
    const urlParams = new URLSearchParams(urlSearch.search)
    const parsedParams = {}
    ALLOWED_PARAMS.forEach(key => {
      const paramValue = urlParams.get(key)
      if (paramValue) {
        parsedParams[key] = paramValue
      }
    })
    return parsedParams
  }

  static save() {
    const times = this.getTimes()
    const currentParams = this.parse()
    const referrer = document?.referrer

    session.set("data", currentParams)

    const isoDate = new Date().toISOString()

    if (referrer) {
      try {
        const hostname = new URL(referrer)?.hostname
        currentParams.referrer = hostname
        times["referrer"] = isoDate
      } catch {}
    }

    const existingParams = this.get()

    const newParams = {}

    const firstVisit = existingParams["first_visit"] === undefined

    newParams["first_visit"] = existingParams["first_visit"] || isoDate
    newParams["last_visit"] = isoDate

    ALLOWED_PARAMS.forEach(key => {
      const initialKey = `initial_${key}`

      if (existingParams[initialKey]) {
        newParams[initialKey] = existingParams[initialKey]
      }

      if (currentParams[key]) {
        times[key] = isoDate
        if (firstVisit) {
          newParams[initialKey] = currentParams[key]
        } else {
          newParams[key] = currentParams[key]
        }
      }
    })

    storage.set("data", newParams)
    updateTimes.set("data", times)

    return true
  }

  static get() {
    return storage.get("data") ?? {}
  }

  static getSession() {
    return session.get("data") ?? {}
  }

  static getTimes() {
    return updateTimes.get("data") ?? {}
  }

  static clear() {
    storage.clearAll()
  }
}

export default UTMParams
