import React, { useEffect } from "react"
import {
  createGlobalStyle,
  StyleSheetManager,
  ThemeProvider,
} from "styled-components"
import { StoreProvider } from "./src/context/store-context"

import "normalize.css"
import "./src/assets/css/global.css"
import { QaProvider } from "./src/context/qa-context"

import UTM from "./src/business/utm"
import ClientCartProvider from "./src/components/provider/client-cart-provider"
import { useShopifyCookies } from "@shopify/hydrogen-react"
import { ModalProvider } from "./src/context/modal-context"

const styleTheme = {
  colors: {
    ci: "#50e3c2",
    blue: "#022a3a",
    green: "#beffde",
    lightestgray: "#eee",
    lightgray: "#ccc",
    gray: "#1f1f1f",
    white: "#fff",
    red: "#ff7e8f",
    purple: "#9013fe",
    trustedShops: "#ffdc0f",
  },
  awesomegrid: {
    columns: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    container: {
      xs: "full",
      sm: 540 / 16,
      md: 720 / 16,
      lg: 960 / 16,
      xl: 1200 / 16,
    },
    breakpoints: {
      xs: 1,
      sm: 36, // 576px
      md: 48, // 768px
      lg: 62, // 992px
      xl: 75, // 1200px
    },
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => props.theme.colors.gray};
    line-height: 1.5;
  }

  p {
    margin-bottom: 0px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  hr {
    background: ${props => props.theme.colors.ci};
    outline: none;
    border-color: ${props => props.theme.colors.ci};
    border-style: solid;
  }
`

const Root = ({ children }) => {
  useEffect(() => {
    UTM.save()
  }, [])

  useShopifyCookies({
    hasUserConsent: true,
    domain: "brandl-nutrition.de",
    checkoutDomain: "checkout.brandl-nutrition.de",
  })

  return (
    <ClientCartProvider>
      <StoreProvider>
        <ModalProvider>
          <QaProvider>
            <StyleSheetManager enableVendorPrefixes>
              <ThemeProvider theme={styleTheme}>
                <GlobalStyle />
                {children}
              </ThemeProvider>
            </StyleSheetManager>
          </QaProvider>
        </ModalProvider>
      </StoreProvider>
    </ClientCartProvider>
  )
}

export default Root
