import { AnalyticsEventName } from "@shopify/hydrogen-react"
import React from "react"
import Root from "./root"
import { trackShopify } from "./src/business/track-event"

export const wrapRootElement = ({ element }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: "optimize.activate" })

  return <Root>{element}</Root>
}

const addFacebookPixel = () => {
  return new Promise((resolve, reject) => {
    if (window.facebookAdded) return resolve(true)

    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = "2.0"
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js",
    )
    /* eslint-enable */

    window.facebookAdded = true

    resolve(true)
  })
}

const initializeFacebook = () => {
  if (!window.facebookInitialized) {
    window.fbq(`init`, "674659822921285")

    window.facebookInitialized = true
  }
}

const trackFacebook = () => {
  if (typeof window.fbq === "function") {
    window.fbq(`track`, `PageView`)
  }
}

export const onClientEntry = () => {
  window.facebookAdded = false
  window.facebookInitialized = false
}

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV === `production`) {
    trackShopify(AnalyticsEventName.PAGE_VIEW, {})

    addFacebookPixel().then(status => {
      if (status && !location.pathname.includes("checkout/success")) {
        initializeFacebook()
        trackFacebook()
      }
    })
  }
}
